<template>
  <KTPortlet :title="userName" headSize="md">
    <template v-slot:body>
      <b-form @submit.prevent="onSubmit" class="edit-user">
        <b-form-group id="avatar-container" label="Hình đại diện">
          <div class="photo-container" @click="$refs.avatar.click()">
            <img :src="previewImage" alt="avatar" />
          </div>
          <input
            ref="avatar"
            type="file"
            accept="image/*"
            @change="getImageData"
          />
        </b-form-group>

        <div class="row">
          <b-form-group label="Tên" class="col-7">
            <b-form-input
              type="text"
              required
              v-model="form.name"
            ></b-form-input>
          </b-form-group>

          <b-form-group label="Tên viết tắt" class="col-5">
            <b-form-input
              type="text"
              required
              v-model="form.shortName"
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="row">
          <b-form-group
            class="col-sm-12 col-md-3 col-lg-3 col-xl-3"
            label="Ngày sinh"
          >
            <b-input-group class="mb-3">
              <b-form-input
                id="dob-input"
                v-model="dobFormat"
                type="text"
                placeholder="DD/MM/YYYY"
                autocomplete="off"
              ></b-form-input>
              <b-input-group-append>
                <b-form-datepicker
                  v-model="form.dob"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric'
                  }"
                  button-only
                  right
                  locale="vie"
                  aria-controls="dob-input"
                  @context="onContext"
                ></b-form-datepicker>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>

          <b-form-group
            class="col-sm-12 col-md-9 col-lg-9 col-xl-9"
            label="Địa chỉ"
          >
            <b-form-input
              type="text"
              required
              v-model="form.address"
            ></b-form-input>
          </b-form-group>
        </div>

        <b-form-group label="Số điện thoại">
          <b-form-input
            type="text"
            required
            v-model="form.phoneNumber"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Email">
          <b-form-input
            type="email"
            disabled
            required
            v-model="form.email"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Tiệm">
          <v-select
            :options="allOutlets"
            multiple
            label="name"
            v-model="outLetsSelected"
          >
            <template v-slot:no-options="{ allOutlets }">
              <em v-if="!allOutlets">Không tìm thấy tiệm nào</em>
            </template>
          </v-select>
        </b-form-group>

        <b-form-group label="Quyền">
          <b-form-select
            v-model="form.role"
            :options="roles"
            :disabled="isAdmin"
          >
          </b-form-select>
        </b-form-group>

        <b-form-group label="Lương">
          <b-form-input v-model="form.salary" type="number" required min="0">
          </b-form-input>
        </b-form-group>

        <b-form-group label="Phần trăm hoa hồng">
          <b-form-input
            v-model="form.commissionPercent"
            type="number"
            min="0"
            required
          >
          </b-form-input>
        </b-form-group>

        <b-form-group label="Trạng thái">
          <b-form-select v-model="form.status" :options="states">
          </b-form-select>
        </b-form-group>

        <b-button type="submit" variant="primary">Hoàn thành</b-button>
      </b-form>
    </template>
  </KTPortlet>
</template>

<style lang="scss"></style>

<script>
import KTPortlet from "@/components/Portlet.vue";
import { UserRole, Status, LABEL_VN } from "./../../constans";
import vSelect from "vue-select";
import Swal from "sweetalert2";
import { getListOutlets } from "@/api/outlet";
import { updateUser } from "@/api/user";
import Loader from "@/common/loader";
import moment from "moment";
import { updateSuccess } from "@/helper/index";
import { log } from 'util';

export default {
  name: "UserCreate",
  components: {
    KTPortlet,
    vSelect
  },
  data() {
    return {
      allOutlets: [],
      states: [
        {
          value: Status.Actived,
          text: "Kích hoạt"
        },
        {
          value: Status.NotActived,
          text: "Chưa kích hoạt"
        }
      ],
      roles: [
        { value: 1, text: "Kỹ thuật viên" },
        { value: 2, text: "Thu ngân" },
        { value: 3, text: "Quản lý tiệm" },
        { value: 4, text: "Chủ tiệm" }
      ],
      form: {
        name: "",
        address: "",
        phoneNumber: "",
        role: 1,
        status: Status.NotActived,
        outletId: [],
        email: "",
        salary: 0,
        commissionPercent: 0,
        dob: ""
      },
      outLetsSelected: [],
      userName: "",
      image: null,
      previewImage: "",
      labelVN: LABEL_VN,
      minDate: new Date(),
      formatted: "",
      selected: "",
      dobFormat: ""
    };
  },
  created() {
    this.$store.dispatch("resetListOutlets").then(() => {
      this.getListOutlet();
    });
  },
  mounted() {
    const item = this.$router.history.current.params?.item;
    if (typeof item === "object") {
      this.form = { ...this.form, ...item };
      if (item.dob) {
        this.form = { ...this.form, ...{ dob: new Date(item.dob) } };
      }
      this.form.outletId = [];
      this.$router.replace({ params: { item: item._id } });
      this.userName = item.name;
      this.previewImage = item.avatar;
      this.dobFormat = moment(this.form.dob).format("DD/MM/YYYY");
    } else {
      return this.$router.push({ name: "user-list", replace: true });
    }

    getListOutlets({ limit: 0, status: 1 }).then(res => {
      this.allOutlets = res.data;
      this.outLetsSelected = this.allOutlets.filter(outlet =>
        item.outletId.find(id => id === outlet._id)
      );
    });
  },
  methods: {
    onContext(content) {
      this.dobFormat = moment(content.selectedDate).format("DD/MM/YYYY");
      this.form.dob = content.selectedDate
    },

    onSubmit() {
      this.outLetsSelected.forEach(element => {
        this.form.outletId.push(element._id);
      });

      const query = {
        id: this.form._id,
        bodyParams: {
          name: this.form.name,
          address: this.form.address,
          phoneNumber: this.form.phoneNumber,
          status: this.form.status,
          role: this.isAdmin ? 5 : this.form.role,
          outletId: this.form.outletId,
          salary: this.form.salary,
          commissionPercent: this.form.commissionPercent,
          avatar: this.previewImage,
          shortName: this.form.shortName,
        }
      };
      if (this.dobFormat) {
        query.bodyParams.dob = new Date(moment(this.dobFormat, 'DD/MM/YYYY').format('MM/DD/YYYY'));
        if (query.bodyParams.dob == "Invalid Date") {
          return Swal.fire({
            icon: "error",
            text: "Ngày tháng năm sinh không hợp lệ!",
            showConfirmButton: true,
            confirmButtonText: "Đồng ý",
          });
        }
      }
      
      updateUser(query)
        .then(() => {
          updateSuccess("user");
        })
        .catch(e => {
          if (e.errors === "Image too big") {
            Loader.close();
            Swal.fire({
              icon: "error",
              text:
                "Hình đại diện có kích thước quá lớn, hãy dùng hình có kích thước nhỏ hơn!",
              showConfirmButton: true,
              confirmButtonText: "Đồng ý"
            });
          }
        });
    },
    getListOutlet() {
      this.$store.dispatch("setOutlets", { status: 1 });
    },

    removeImage() {
      this.previewImage = "";
    },

    getImageData(evt) {
      const image = evt.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = e => {
        this.previewImage = e.target.result;
      };
    }
  },
  computed: {
    isAdmin() {
      return this.form.role === 5 ? true : false;
    },
  },
};
</script>
